export const getBreakpoint = () => {
    let breakpoints = window.breakpoints

    if(!breakpoints) return false

    if(window.matchMedia(`(min-width: ${breakpoints.large})`).matches) {
        return 'desktop'
    }
    else if(window.matchMedia(`(max-width: ${breakpoints.small})`).matches) {
        return 'phone'
    }
    else {
        return 'tablet'
    }
}

const getParentWithStyle = (elem, style, value) => {
    do  {
        if(!elem) return false

        let _style = getComputedStyle(elem)[style]
        let _value = value.split(',')

        if(_value.includes(_style)) return elem;
    }
    while(elem = elem.offsetParent);
    return false;
}

export const setModalOverflow = (modal) => {

    if(!modal || getBreakpoint() != 'desktop') return

    let rect = modal.getBoundingClientRect()
    let bottom = rect.top + modal.scrollHeight
    let fixedParent = getParentWithStyle(modal.offsetParent, 'position', 'sticky, fixed')
    let scrollableParent = getParentWithStyle(modal.offsetParent, 'overflow', 'scroll')

    let cutoff = fixedParent && $(fixedParent).find($(scrollableParent)).length == 0 && !$(fixedParent).is($(scrollableParent))

    if(cutoff && bottom > window.innerHeight) {
        $(modal).css({
            "height": window.innerHeight - rect.top + "px",
            "overflow": "scroll"
        })
    } else {
        modal.style.removeProperty('height')
        modal.style.removeProperty('overflow')
    }
}
