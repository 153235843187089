import { h, render, Component } from 'preact';
import Select from '../components/Select.js'

class Restaurants extends Component {
    constructor(props) {
        super();

        this.state = {
            restaurants: []
        }
    }

    componentDidMount(props) {

        (new OJ.DataConnector()).getRestaurants((restaurants) => {
            if(typeof(restaurants) == 'object'){
                this.setState({
                    restaurants: restaurants
                })
            }
        })
    }

    render(props) {
        return <Select
                label="Restaurants"
                placeholder="Select restaurant"
                options={ this.state.restaurants.map((restaurant) => {
                    return {
                        'key': restaurant['key'],
                        'value': restaurant['value'],
                        'image': restaurant['image']
                    }
                })}
                name="restaurant"
                required={ true }
                onChange={ this.props.onValueChange }
                active={ this.props.active }
                value={ props.value }
            />
    }
}

export default Restaurants