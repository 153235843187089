import { h, render, Component } from 'preact';
import { setModalOverflow } from '../Utils.js'

class Guests extends Component {
    constructor(props) {
        super();

        this.state = {
            adults: props.currentValues.adults ? Math.min(props.currentValues.adults, props.maxAdults) : props.defaultAdults,
            infants: props.currentValues.infants ? Math.min(props.currentValues.infants, props.maxChildren) : 0,
            valid: false
        }

        this.updateNumberField = this.updateNumberField.bind(this)
        this.updateFormValues = this.updateFormValues.bind(this)
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.getLimit(nextState)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((prevState.adults + prevState.infants) != (this.state.adults + this.state.infants)) {
            this.updateFormValues()
        }
    }

    componentDidMount() {
        this.updateFormValues(false)
    }

    updateFormValues(updateStage = true) {
        this.props.onValueChange({
            adults: this.state.adults,
            infants: this.state.infants
        }, updateStage)
    }

    updateNumberField(model, amount) {
        let modelUpdates = []

        modelUpdates[model] = this.state[model] + amount

        if(this.getLimit(modelUpdates)) {
            this.setState({...modelUpdates, ...{valid: true}})
        }
    }

    getLimit(nextState) {
        let max = nextState.adults <= this.props.maxAdults
        let min = nextState.adults >= 1

        if(nextState.infants != null) {
            max = nextState.infants <= this.props.maxChildren
            min = nextState.infants >= 0
        }

        return max && min
    }

    getButtonState(model, direction) {
        let min = model == "infants" ? 0 : 1;
        let max = model == "infants" ? this.props.maxChildren : this.props.maxAdults;
        return this.state[model] == (direction == -1 ? min : max) ? " is-disabled" : ""
    }

    render(props) {
        let adults = this.props.currentValues.adults || this.state.adults
        let infants = this.props.currentValues.infants || this.state.infants

        return <div class={"c-onejourney-search__field c-onejourney-search__field--select is-required" + (this.state.valid ? " is-valid" : "")}>

                <div class="c-onejourney-search__field-text">
                    <span class="c-onejourney-search__field-label">Guests</span>
                    <p class="c-onejourney-search__field-value">{ adults == 0 && infants == 0 ? "Add" : (adults + infants) } guests</p>
                </div>

                <div class="c-onejourney-search__field-options"  style={ !props.active ? "display: none" : "" } ref={modal => setModalOverflow(modal)}>

                    <div class="c-booking-form__stepper">
                        <div class="c-booking-form__stepper-text">
                            <label class="c-booking-form__stepper-label">Adults</label>
                            <span class="c-booking-form__stepper-definition">{ props.adultDefinition }</span>
                        </div>

                        <button
                            type="button"
                            class={ "c-booking-form__stepper-button c-booking-form__stepper-button--decrease" + this.getButtonState('adults', -1) }
                            onClick={ () => this.updateNumberField('adults', -1) }
                        >
                        </button>

                        <span class="c-booking-form__stepper-value">{ adults }</span>

                        <button
                            type="button"
                            class={ "c-booking-form__stepper-button c-booking-form__stepper-button--increase" + this.getButtonState('adults', 1) }
                            onClick={ () => this.updateNumberField('adults', 1) }
                        >
                        </button>
                    </div>

                    { props.showChildren &&

                        <div class="c-booking-form__stepper">
                            <div class="c-booking-form__stepper-text">
                                <label class="c-booking-form__stepper-label">Children</label>
                                <span class="c-booking-form__stepper-definition">{ props.childDefinition }</span>
                            </div>

                            <button
                                type="button"
                                class={ "c-booking-form__stepper-button c-booking-form__stepper-button--decrease" + this.getButtonState('infants', -1) }
                                onClick={ () => this.updateNumberField('infants', -1) }
                            >
                            </button>

                            <span class="c-booking-form__stepper-value">{ infants }</span>

                            <button
                                type="button"
                                class={ "c-booking-form__stepper-button c-booking-form__stepper-button--increase" + this.getButtonState('infants', 1) }
                                onClick={ () => this.updateNumberField('infants', 1) }
                            >
                            </button>
                        </div>

                    }

                    <button type="button" class="c-onejourney-search__field-close" onClick={ () => $(document).trigger('oj_search_close') }><span>Close</span></button>

                </div>

                <input type="hidden" name="adults" value={ adults } />
                <input type="hidden" name="infants" value={ infants } />
            </div>

    }
}

export default Guests