import { h, render, Component } from 'preact';
import Select from '../components/Select.js'

class SpaSelector extends Component {
    render(props) {
        let allSpaTypes = [
            {
                "key": "spa_days",
                "value": "Spa days",
            },
            {
                "key": "spa_packages",
                "value": "Spa packages",
            },
            {
                "key": "treatments",
                "value": "Treatments",
            }
        ]
        let availableSpaTypes = allSpaTypes.reduce((filtered, type) => {
            let matchingCategories = props.categories.filter((category) => category.has.includes(type.key))

            if(matchingCategories.length) {
                type['image'] = matchingCategories[0].image
                filtered.push(type)
            }

            return filtered
        }, [])


        return  <Select
                label="Type"
                placeholder="Select type"
                options={ availableSpaTypes }
                name="spaType"
                required={ true }
                onChange={ props.onValueChange }
                active={ props.active }
                value={ props.value }
                submit={ false }
            />

    }
}

export default SpaSelector