import { h, render, Component, cloneElement } from 'preact';

class SearchForm extends Component {
    constructor(props) {
        super();

        this.state = {
            stages: []
        }

        this.getStages = this.getStages.bind(this)
    }

    componentDidUpdate() {
        let stages = this.getStages()

        if(JSON.stringify(stages) != JSON.stringify(this.state.stages)) {
            this.setState({
                stages: stages
            })
            this.props.updateStages(stages)
        }

        $('body').toggleClass('onejourney-search-form-is-active', this.props.stage != null)
    }

    getStages() {
        return this.props.children.map((child) => child && child.props ? child.props.stage : false).filter(content => content)
    }

    render(props) {

        let stages = this.getStages()
        let finalStage = stages.indexOf(props.stage) == stages.length -1

        let form = <form
            onSubmit={ props.onSubmit }
            action={ props.action }
            method="GET"
            target="_blank"
            class={"c-onejourney-search__form" + (props.pricingData ? " has-pricing-data" : "") + (props.stage ? " is-active" : "")}
            >

            { props.children.map((child) => {

                if(!child) return

                return cloneElement(child)
            }) }

            <section class={ "c-onejourney-search__section c-onejourney-search__section--search" + (finalStage ? " is-active" : "")}>
                <nav class="c-onejourney-search__nav">
                    <button type="submit">
                        <svg xmlns='http://www.w3.org/2000/svg' width='14.6' height='14.6'><path d='M6 1.5A4.5 4.5 0 1 0 10.5 6 4.5 4.5 0 0 0 6 1.5M6 0a6 6 0 1 1-6 6 6 6 0 0 1 6-6Z'/><path d='m13.1 14.5-3.7-4 1-1 3.8 4Z'/></svg>
                        { props.buttonText ? props.buttonText : "Search" }
                    </button>
                </nav>
            </section>

        </form>

        return [
            <div class="c-onejourney-search__main">
                { form }
            </div>,
            <div class={"c-onejourney-search__offcanvas" + (props.stage ? " is-active" : "")}>
                { form }
            </div>
        ]
    }
}

export default SearchForm