import { h, render, Component, Fragment } from 'preact';
import { setModalOverflow } from '../Utils.js'

class Datepicker extends Component {
    constructor(props) {
        super();

        this.state = {
            fromDate: {
                display: props.currentValues.fromDate ? props.currentValues.fromDate.display : 'Add date',
                submit: props.currentValues.fromDate ? props.currentValues.fromDate.submit : '',
            },
            toDate: {
                display: props.currentValues.toDate ? props.currentValues.toDate.display : 'Add date',
                submit: props.currentValues.toDate ? props.currentValues.toDate.submit : '',
            },
            pickingDate: '',
            valid: false,
            datepicker: null
        }

        this.placeholders = {
            fromDate: this.state.fromDate.display,
            toDate: this.state.toDate.display
        }
        this.year = new Date().getFullYear()

        this.AvailabilityRates = new OJ.AvailabilityRates(props.hotelID)

        this.showDatePicker = this.showDatePicker.bind(this)
        this.clearDate = this.clearDate.bind(this)
    }

    componentDidMount() {
        this.makeDatepicker()
    }

    componentDidUpdate(prevProps) {
        if(prevProps.toggleToDate != this.props.toggleToDate) {
            this.clearDate('fromDate')
            this.makeDatepicker()
        }

        if(prevProps.showPrices != this.props.showPrices) {
            this.AvailabilityRates.togglePrices(this.props.showPrices)
        }

        if(prevProps.showAvailability != this.props.showAvailability) {
            this.AvailabilityRates.toggleAvailability(this.props.showAvailability)
        }

        if(prevProps.rateCode != this.props.rateCode) {
            this.AvailabilityRates.setRate(this.props.rateCode || null, true)
        }

        if(prevProps.roomID != this.props.roomID) {
            this.AvailabilityRates.setRoom(this.props.roomID || null, true)
        }

        if(!prevProps.active && this.props.active) this.showDatePicker()
        if(prevProps.active && !this.props.active) this.hideDatePicker()
    }

    makeDatepicker() {
        let datepickerConfig = {
            mode: this.props.toggleToDate ? 'range' : 'single',
            inline: true,
            showMonths: 2,
            monthSelectorType: 'static',
            disableMobile: false,
            minDate: new Date(),
            locale: {
                firstDayOfWeek: 1,
                weekdays: {
                    shorthand: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
                }
            },
            onChange: this.setDatesFromDatepicker.bind(this),
            onReady: (dates, dateStr, instance) => {
                this.calendarContainer = instance.calendarContainer
                this.setState({
                    datepicker: instance
                })
                if(this.props.active) this.showDatePicker()
            }
        }

        if(this.props.currentValues.fromDate) {
            datepickerConfig['defaultDate'] = [this.props.currentValues.fromDate.submit]
            if(this.props.toggleToDate) datepickerConfig['defaultDate'][1] = this.props.currentValues.toDate.submit
        }

        if(this.props.rateCode) {
            this.AvailabilityRates.setRate(this.props.rateCode)
        }

        datepickerConfig['minDate'] = this.AvailabilityRates.setMinDate(this.props.leadTime)

        this.AvailabilityRates.setDisplayPrices(this.props.showPrices)
        this.AvailabilityRates.setDisplayAvailability(this.props.showAvailability)
        this.AvailabilityRates.setMinStay(this.props.minStay)
        this.AvailabilityRates.makeDatepicker(this.datepicker, datepickerConfig)
    }

    setDatesFromDatepicker(selectedDates) {
        let fromDate = selectedDates[0]
        let toDate = !this.props.toggleToDate ? fromDate : selectedDates[1]

        let updates = {
            fromDate: this.getDateObject(fromDate, this.placeholders.fromDate),
            toDate: this.getDateObject(toDate, this.placeholders.toDate)
        }

        if(toDate) {
            updates['valid'] = true
            updates['pickingDate'] = ''
        } else {
            updates['pickingDate'] = selectedDates.length == 1 ? 'toDate' : ''
        }

        this.setState(updates)

        let { valid, pickingDate, ...fieldValues} = updates

        Object.keys(fieldValues).forEach((field) => {
            if(fieldValues[field].submit == '') fieldValues[field] = false
        })

        this.props.onValueChange(fieldValues)

    }

    getDateObject(date, placeholder) {
        let display = 'D, j M';

        return {
            display: date ? flatpickr.formatDate(date, display) : placeholder,
            submit: date ? flatpickr.formatDate(date, 'Y-m-d') : ''
        }
    }

    showDatePicker() {
        $(this.calendarContainer).addClass('is-active')

        this.setState({
            pickingDate: 'fromDate'
        })
    }

    hideDatePicker() {
        $(this.calendarContainer).removeClass('is-active')

        this.setState({
            pickingDate: ''
        })
    }

    clearDate(dateToClear) {
        this.setState({
            fromDate: {
                display: this.placeholders.fromDate,
                submit: ''
            },
            toDate: {
                display: this.placeholders.toDate,
                submit: ''
            },
            pickingDate: dateToClear
        })

        this.state.datepicker.clear()

        if(dateToClear == 'toDate') {
            this.state.datepicker.setDate(this.state.fromDate.submit, true)
        }
    }

    render(props) {

        let fromDate = this.props.currentValues.fromDate || this.state.fromDate
        let toDate = this.props.currentValues.toDate || this.state.toDate


        return <div class={"c-onejourney-search__field-group" + (props.showPrices ? " has-booking-data" : "")} ref={container => this.container = container}>

                    <div class={ "c-onejourney-search__field is-required" + (this.state.pickingDate == "fromDate" ? " is-active" : "") + (this.state.valid ? " is-valid" : "") + (fromDate.submit != '' ? " is-complete": "") }>

                        <div class="c-onejourney-search__field-text">
                            <label class="c-onejourney-search__field-label">{ props.toggleToDate ? "Check-in" : "Date" }</label>
                            <span class="c-onejourney-search__field-value">{ fromDate.display }</span>
                            <span class="c-onejourney-search__field-clear" onClick={ () => this.clearDate('fromDate') }></span>
                        </div>

                        <input type="hidden" name={ props.type == 'spa' ? "arrivalDate" : "fromDate"} value={ fromDate.submit } />
                    </div>

                    { props.toggleToDate &&

                        <Fragment>
                            <div class="c-onejourney-search__field-separator">
                                <span class="c-onejourney-search__field-arrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.4" height="11.8"><g fill="none" stroke="#dbdbdb" stroke-linecap="square"><path d="M.5 5.9H10"/><path d="M5.9.7 11 6 6 11" stroke-linejoin="bevel"/></g></svg>
                                </span>
                            </div>

                            <div class={ "c-onejourney-search__field is-required" + (this.state.pickingDate == "toDate" ? " is-active" : "") + (this.state.valid ? " is-valid" : "") + (toDate.submit != '' ? " is-complete": "") }>

                                <div class="c-onejourney-search__field-text">
                                    <label class="c-onejourney-search__field-label">{ props.toggleToDate ? "Check-out" : "Date" }</label>
                                    <span class="c-onejourney-search__field-value">{ toDate.display }</span>
                                    <span class="c-onejourney-search__field-clear" onClick={ () => this.clearDate('toDate') }></span>
                                </div>

                                <input type="hidden" name="toDate" value={ toDate.submit } />
                            </div>
                        </Fragment>
                    }

                    <div class={"c-onejourney-search__date-input" + (!props.toggleToDate ? " single-month" : "")} ref={modal => setModalOverflow(modal)}>
                        <input class="js-booking-form-datepicker" ref={datepicker => this.datepicker = datepicker}/>
                        <button type="button" class="c-onejourney-search__field-close" onClick={ () => $(document).trigger('oj_search_close') }><span>Close</span></button>
                    </div>

                    <nav class="c-onejourney-search__nav">
                        <button type="button" class="c-onejourney-search__section-next" onClick={ (e) => props.onNextClick(e, 1) } disabled={ !toDate.submit }>Next</button>
                    </nav>

                </div>

    }
}

export default Datepicker